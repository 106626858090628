<template>
  <p class="stage-title">Current Level: {{ progressBarInfo.level_name }}</p>
  <div class="progress-container">
    <div class="progress-bar" :style="{ width: progressPercentage + '%' }">
      <span class="progress-value">{{ progressBarInfo.current_sublevel }} / {{ progressBarInfo.sublevel_qtt }}</span>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';

// 定义props
const props = defineProps({
  progressBarInfo: Object
});

// 计算进度百分比
const progressPercentage = computed(() => ((props.progressBarInfo.current_sublevel / props.progressBarInfo.sublevel_qtt) * 100).toFixed(0));
</script>

<style scoped>
.progress-container {
  border-radius: 5px;
  background-color: #f0f0f0;  /* 添加的底色 */
}

.stage-title {
  text-align: left;
}

.progress-bar {
  height: 20px;
  border-radius: 5px;
  overflow: hidden;
}

.progress-bar .progress-value {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.progress-bar > span {
  color: white;
  background-color: #4caf50;
}
p{
  margin-bottom: 0;
  padding-bottom: 0;
}
</style>