<template>
  <div v-if="wordData" class="exercise-container">
    <!-- 单词按钮 -->
    <div class="btn-group">
      <button v-for="(displayWord, idx) in randomWords" :key="idx" class="word-button" @click="checkWord(displayWord)">
        <span v-for="(part, index) in displayWord.parts" :key="index" :style="{ color: index === 1 ? 'red' : 'black' }">
          {{ part }}
        </span>
      </button>
    </div>
    <img :src="wordData.img_src" alt="word image">
    <!-- 显示选择正确的单词 -->
    <div v-if="selectedDisplayWord" class="word-display" >
      <span v-for="(part, index) in selectedDisplayWord.parts" :key="index" :style="{ color: index === 1 ? 'red' : 'black' }">
        {{ part }}
      </span>
    </div>
    <div v-else class="answer_word">{{wordData.word}} </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  waiting_for_answer: Boolean,
  words: Array, // 示例: [{ word: "phone", phonic: "ph" }, { word: "laugh", phonic: "gh" }]
  wordData: Object // 示例: {word:"phone", img_src:"path.jpg"}
});
const emits = defineEmits(['onAnswerCorrect']);

const selectedDisplayWord = ref(null);

const checkWord = (word) => {
  if (word.word !== props.wordData.word) return;
  // 如果选对了
  selectedDisplayWord.value = word;
  // 触发父元素的方法
  emits('onAnswerCorrect');
};

watch(() => props.waiting_for_answer, (waiting) => {
  if (waiting) {
    selectedDisplayWord.value = null;
  }
});

function splitWords(words) {
  return words.map(word => {
    const index = word.word.indexOf(word.phonic);
    return {
      word: word.word,
      parts: index === -1 ? [word.word] : [
        word.word.slice(0, index),
        word.word.slice(index, index + word.phonic.length),
        word.word.slice(index + word.phonic.length)
      ]
    };
  })
}

const randomWords = computed(() => {
  if (props.words.length < 3){
    return splitWords(props.words)
  }
  // 确保包含正确答案的单词
  const correctWord = props.words.find(w => w.word === props.wordData.word);
  // 从数组中排除正确的单词
  const otherWords = props.words.filter(w => w.word !== props.wordData.word);
  // 打乱剩余单词数组
  let shuffled = otherWords.sort(() => 0.5 - Math.random());
  // 选择两个单词并添加正确的单词
  let selected = shuffled.slice(0, 2);
  selected.push(correctWord);
  // console.log(props.words)
  // 再次打乱以增加随机性
  return splitWords(selected.sort(() => 0.5 - Math.random()))
});
</script>


<style scoped>
.exercise-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'MV Boli';
  margin-bottom: 10px;
  font-size: 10vw;
}
img {
  /* 设置最小宽度和高度为视口宽度和高度的一定百分比 */
  min-width: 90vw;
  /* min-height: 90vh; */
  
  /* 设置最大宽度和高度为视口宽度和高度的一定百分比，确保不超过视口大小 */
  max-width: 90vw;
  max-height: 50vh;
  
  /* 确保图片在容器内完整显示，不会溢出 */
  object-fit: contain;
  
  /* 如果图片原始尺寸小于容器，它将被拉伸以填满容器 */
  width: 100%;
  height: 100%;
}
.word-display {
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-group {
  display: flex;
}

.btn-group .word-button {
  display: inline-block;
  margin: 1vw;
  padding: 1vw 2vw;
  border-radius: 1vw;
  font-size: 6vw;
  background-color: wheat;
}
.answer_word{
  color: white;
}
.word-display, .answer_word{
  border-bottom: 3px solid black;

}
</style>