<template>
  <div class="register-container">
    <h2>Register</h2>
    <form @submit.prevent="register">
      <input type="text" placeholder="Username" v-model="username" ref="usernameInput" />
      <input type="password" placeholder="Password" v-model="password" />
      <button type="submit">Register</button>
    </form>
  </div>
</template>  
  
<script setup>
import { ref, onMounted } from 'vue';
import { apiClient } from "@/api/apiClient";
import { ROUTES_WITH_BASE_URL } from "@/api/apiRouters";
import { useRouter } from 'vue-router';

const router = useRouter()
const usernameInput = ref(null)
const username = ref('');
const password = ref('');

// 注册方法  
const register = async () => {
  try {
    const response = await apiClient.post(ROUTES_WITH_BASE_URL.REGISTER, {
      username: username.value,
      password: password.value
    });
    console.log(response)
    // 注册成功逻辑  
    console.log("注册成功"); // 打印响应数据  
    router.push('/login')
  } catch (error) {
    console.log(error)
  }
};
onMounted(() => {
  // 在组件挂载后，自动获取焦点
  if (usernameInput.value) {
    usernameInput.value.focus();
  }
});
</script>
  
<style setup>
.register-container {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #28a745;
  /* Green color for register button */
  color: white;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}
</style>
  