import axios from 'axios'

const apiClient = axios.create({
  // 这里可以全局设置默认的Content-Type，但通常不建议这么做，因为不同的请求可能需要不同的Content-Type
  headers: {
    'Content-Type': 'application/json'
  }
})

// 请求拦截器
apiClient.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('access_token')

    if (accessToken) {
      // config.headers['Authorization'] = `Bearer ${accessToken}`
      config.headers['Authorization'] = `Token ${accessToken}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// 响应拦截器
apiClient.interceptors.response.use(
  (response) => {
    // 如果响应状态码正常，则直接返回响应
    return response
  },
  async (error) => {
    // 如果响应状态码为401，则尝试刷新token
    if (error.response && error.response.status === 401) {
      try {
        console.log('401错误码')
      } catch (refreshError) {
        // 刷新token失败，用户需要重新登录
        // window.location.href = ROUTES.LOGIN; // 假设您的登录页面在/login
        return Promise.reject(refreshError)
      }
    }

    // 如果不是401错误，直接返回错误
    return Promise.reject(error)
  }
)
export {apiClient}
