<template>
  <nav class="top-bar">
    <div class="logo"><router-link to="/">Bunny Phonics</router-link></div>
    <div v-if="!isLoggedIn" class="info">
      <div class="item"><router-link to="/register">注册</router-link></div>
      <div class="item"><router-link to="/login">登录</router-link></div>
    </div>
    <div v-else class="info">
      <!-- 用户已登录时显示 -->
      <div class="one-line item">Welcome, <router-link to="/profile">{{ username }}</router-link>!</div>
      <a @click="logout" class="item">登出</a>
    </div>

  </nav>
</template>  

  
<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from 'vuex';
import { useRouter } from 'vue-router'

const store = useStore();
const router = useRouter()

// 使用计算属性来判断用户是否已经登录
const isLoggedIn = ref(computed(() => store.getters.isLoggedIn))

// 获取已登录用户的用户名
const username = ref(computed(() => store.getters.username))

function logout() {
  store.dispatch('logout');
  // location.reload();
  router.push('/')
}
onMounted(()=>{
  if(localStorage.getItem('isLoggedIn')){
    store.commit('setLoginStatus',{isLoggedIn:true, username:localStorage.getItem('username')})
  }
})
</script>
  
<style scoped>  
.top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #bdb1b1;
  color: #fff;
  padding: 10px 20px;
}
.info{
  display: flex;
  justify-content: center;
  align-items: center;
}
.one-line {
  white-space: nowrap;
}
.info button{
  border-radius: 5px;
}
.info .item{
  margin: auto .3em;
}
.info a:hover{
  cursor: pointer;
  color: black;
}
</style>