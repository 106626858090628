import { createStore } from 'vuex';  
import {ROUTES_WITH_BASE_URL} from "@/api/apiRouters"
import {apiClient} from "@/api/apiClient"

// 创建一个新的 store 实例  
const store = createStore({  
  state: {  
    isLoggedIn: false,  
    username: null,  
  },  
  getters: {
    // 获取用户是否登录的状态
    isLoggedIn: (state) => state.isLoggedIn,
    // 获取用户信息
    username: (state) => state.username,
  },
  mutations: {  
    // 设置用户登录状态和用户信息
    setLoginStatus(state, payload) {
      state.isLoggedIn = payload.isLoggedIn;
      state.username = payload.username;
    },
  },  
  actions: {
    // 用户登录
    async login({ commit }, credentials) {
      // 假设这是一个异步的登录操作
      await apiClient.post(ROUTES_WITH_BASE_URL.LOGIN, credentials)
      .then((response) => {
        // 登录成功，设置用户登录状态和用户信息
        localStorage.setItem('access_token', response.data.access); // 假设后端返回的是access_token  
        localStorage.setItem('isLoggedIn', true); 
        localStorage.setItem('username', response.data.username); 
        commit('setLoginStatus', { isLoggedIn: true, username: response.data.username });
        return true
      })
      .catch((error) => {
        // 登录失败，可以处理错误
        localStorage.removeItem('access_token'); // 清除访问令牌  
        console.error('Login failed:', error);
        return false
      });
    },
    // 用户登出
    async logout({ commit }) {
      // 设置用户为未登录状态
      commit('setLoginStatus', { isLoggedIn: false, username: null });
      // 重定向到登录页面或其他页面    
      localStorage.removeItem('access_token');
      localStorage.setItem('isLoggedIn', '');
      localStorage.setItem('username', '');
      try {
        await apiClient.post(ROUTES_WITH_BASE_URL.LOGOUT); // 确保这是正确的URL  
      } catch (error) {
        console.error('Logout failed:', error);
      }
    }
  },  
  modules: {  
    // 如果有模块化的状态管理，可以在这里定义  
  }  
});  
  
export default store;