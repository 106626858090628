<template>
  <div v-if="wordData" class="exercise-container">
    <img :src="wordData.img_src" alt="word image">
    <div class="word-display">
      <span
        v-for="(part, index) in splitWord" :key="index" 
        :style="{ color: index === matchIndex ? color : 'black', borderBottom: part == wordData.phonic ? '3px solid black' : '3px solid white' }" 
      >
        {{ part }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  phonic: String,
  wordData: Object,
});
const emits = defineEmits('onAnswerCorrect');

const color = computed(() => props.phonic == props.wordData.phonic ? 'red' : 'white');
const splitWord = computed(() => {
  console.log('in fill ',props.wordData)
  if (! props.wordData) return
  const index = props.wordData.word.indexOf(props.wordData.phonic);
  if (index === -1) return [props.wordData.word];
  return [
    props.wordData.word.slice(0, index),
    props.wordData.word.slice(index, index + props.wordData.phonic.length),
    props.wordData.word.slice(index + props.wordData.phonic.length)
  ];
});
const matchIndex = computed(() => splitWord.value.length === 3 ? 1 : -1);

// 回答正确
watch(() => props.phonic, (newValue) => {
  if (newValue === props.wordData.phonic) {
    emits('onAnswerCorrect')
  }
});
</script>

<style scoped>
.exercise-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'MV Boli';
  margin-bottom: 10px;
}
img {
  /* 设置最小宽度和高度为视口宽度和高度的一定百分比 */
  min-width: 90vw;
  /* min-height: 90vh; */
  
  /* 设置最大宽度和高度为视口宽度和高度的一定百分比，确保不超过视口大小 */
  max-width: 90vw;
  max-height: 50vh;
  
  /* 确保图片在容器内完整显示，不会溢出 */
  object-fit: contain;
  
  /* 如果图片原始尺寸小于容器，它将被拉伸以填满容器 */
  width: 100%;
  height: 100%;
}
.word-display {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10vw;
}
</style>
