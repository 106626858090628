// baseURL和ROUTES
import { baseURL } from "./settings"

const ROUTES = {
  REFRESH_TOKEN: 'token/refresh/',
  LOGIN: 'login/',
  LOGOUT: 'logout/',
  REGISTER: 'register/',
  get_practice_data:"get_practice_data/",
  data_after_practice:"data_after_practice/",
  get_gained_words_data:"get_gained_words_data/",
  profile:"profile/",
  levels:"levels/",
  set_level:"set_level/"
}

// 使用map和其他方法为每个路由值添加baseURL
const ROUTES_WITH_BASE_URL = Object.fromEntries(
  Object.entries(ROUTES).map(([key, value]) => [key, baseURL + value])
)

export { ROUTES_WITH_BASE_URL }
