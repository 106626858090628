<template>  
  <div class="auth-container">  
    <h2>Login</h2>  
    <form @submit.prevent="login">  
      <input type="text" v-model="username" placeholder="Username" ref="usernameInput" />  
      <input :type="inputType" v-model="password" placeholder="Password" @click="togglePasswordVisibility" />  
      <button type="submit">Login</button>  
    </form>  
  </div>  
</template>  
  
<script setup>  
import { ref , onMounted, nextTick} from 'vue';  
import { useRouter } from 'vue-router'; // 引入Vue Router的useRouter钩子  
import {useStore} from 'vuex'

const usernameInput = ref(null)
const store = useStore()
const username = ref('');  
const password = ref('');  
const inputType = ref('password');  
const router = useRouter(); // 使用Vue Router的useRouter钩子获取router实例  

const togglePasswordVisibility = () => {  
  inputType.value = inputType.value === 'password' ? 'text' : 'password';  
}
const login = async () => {  
  const credentials = { username: username.value, password: password.value }; 
  const success = store.dispatch('login', credentials);
  if (success){
    await nextTick();  // 确保所有的数据都已更新
    router.push('/')
  }
}
onMounted(() => {
  // 在组件挂载后，自动获取焦点
  if (usernameInput.value) {
    usernameInput.value.focus();
  }
});
</script> 
  
<style scope>
.auth-container {
  max-width: 300px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

input[type="text"],
input[type="password"] {
  width: 90%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  width: 100%;
  padding: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}
</style>
  