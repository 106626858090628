<template>
  <div class="phonics-group" ref="phonicsContainer">
    <div
      class="phonic-box" v-for="(item, index) in phonics" :key="index"
      @click="handlePhonicsClick(index, item.phonic)"
    >
      <audio ref="audioRefs" :src="item.audio_src" type="audio/mpeg">
        Your browser does not support the audio element.
      </audio>
      <div class="phonic-content" >
        {{ item.display }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from 'vue';

defineProps({
  phonics: {
    type: Array,
    required: true,
  },
});
// 定义要发出的事件
const emits = defineEmits(['phonic-clicked']);

const audioRefs = ref(null);
let activeAudio = null
const handlePhonicsClick = (index, phonic) => {
    // 检查当前活动的音频是否与点击的音频相同
    if (activeAudio && activeAudio === audioRefs.value[index]) {
        if (!activeAudio.paused) {
            // 如果音频正在播放，先暂停它
            activeAudio.pause();
        }
        // 无论音频的当前状态如何，都将时间重置到开始
        activeAudio.currentTime = 0;
        // 重新播放音频
        activeAudio.play();
    } else {
        // 如果点击的是不同的音频，处理逻辑与之前相同
        if (activeAudio && !activeAudio.paused) {
            activeAudio.pause();
        }
        activeAudio = audioRefs.value[index];
        activeAudio.currentTime = 0;
        activeAudio.play();
    }

  // 变色
  const phonicBox = document.querySelector(`.phonic-box:nth-child(${index+1})`);
  phonicBox.style.backgroundColor = 'lightgreen';
  setTimeout(() => {
    phonicBox.style.backgroundColor = '';
  }, 500);

  // 向父组件发送事件
  emits('phonic-clicked', phonic);
};

onMounted(() => {
  // console.log(audioRefs.value); // 打印所有 <audio> 元素的引用
});
</script>

<style scoped>
.phonics-group {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
  font-family: 'MV Boli';
  font-size: 10vw;
}

.phonic-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  background-size: cover;
  cursor: pointer;
  transition: background-color 0.5s;
  border-radius: 15px;
}

.phonic-content {
  color: #333;
  user-select: none; /* 防止选中文本 */
  margin: auto .15em;
}

</style>