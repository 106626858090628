import { createRouter, createWebHistory } from 'vue-router'
import LoginTest from '@/components/LoginTest.vue'
import RegisterTest from '@/components/RegisterTest.vue'
import ExerciseView from '@/views/ExerciseView.vue'
import test from '@/views/ProfileView.vue'

const routes = [
  {
    path: '/',
    name: 'ExerciseView',
    component: ExerciseView
  },
  {
    path: '/login',
    name: 'LoginTest',
    component: LoginTest
  },
  {
    path: '/register',
    name: 'RegisterTest',
    component: RegisterTest
  },
  {
    path: '/profile',
    name: 'profile',
    component: test
  },
  // 其他路由...
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
