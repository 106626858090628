<template>
  <div class="app-container" v-if="isLoggedIn">
    <button v-if="! ready" @click="get_practice_data()">获取数据</button>
    <div v-if="ready">
      <PhonicsGroup :phonics="practice_data.phonics" @phonic-clicked="handlePhonicClick" />
      <div class="exercise">
        <button @click="startExercise" v-if="!exerciseStarted">开始</button>
        <div v-if="exerciseStarted">
          <ExerciseFill v-if="exercise_type === 'fill'" :waiting_for_answer="waiting_for_answer" :phonic="selectedPhonic" :wordData="wordData" @onAnswerCorrect="answerCorrect" />
          <ExerciseFillWord v-if="exercise_type == 'fill_word'" :waiting_for_answer="waiting_for_answer" :words="practice_data.words" :wordData="wordData" @onAnswerCorrect="answerCorrect" />
        </div>
      </div>
      <div class="control-buttons" v-show="exerciseStarted">
        <button @click="handleButtonClick('master')">Master</button>
        <!-- <button @click="handleButtonClick('know')">Know</button> -->
        <button @click="handleButtonClick('next')">Next</button>
      </div>
    </div>
    <!-- <img src="http://localhost:8000/app2/levels/1.short vowels/3.-all, -ell,(f)ull/img/ball_3.jpg" alt=""> -->
  </div>
  <div style="text-align:center;" v-else>
    <a style="position:fixed; bottom:0;" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备20032597号-1</a>

  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { useStore } from 'vuex';
import { apiClient } from "@/api/apiClient";
import { ROUTES_WITH_BASE_URL } from "@/api/apiRouters";
import PhonicsGroup from '@/components/PhonicsGroup.vue'
import ExerciseFill from '@/components/ExerciseFill.vue';
import ExerciseFillWord from '@/components/ExerciseFillWord.vue';

const store = useStore()
const isLoggedIn = ref(computed(() => store.getters.isLoggedIn))
// 初始化后端获取的数据
const practice_data = ref({})
const ready = ref(false)

// 初始化用于 exercise 组件的参数
const wordData = ref({});

const exerciseStarted = ref(false); // 练习开始开关

const waiting_for_answer = ref(false) // 允许答题的开关
const currentIndex = ref(0); // 当前题目索引

const selectedPhonic = ref(null)

// 用以判断题型，从而有不同的函数，比如有的要填数有的不用
const exercise_type = ref(null);

// 开始练习函数
function startExercise() {
  currentIndex.value = 0; // 从第一个单词开始
  emitExercise();
  selectedPhonic.value = null;
  exerciseStarted.value = true;

}
const correctAudio = new Audio(require('@/assets/audios/correct.aac'))
// 子组件emit回答正确
function answerCorrect() {
  if (!waiting_for_answer.value || ! exerciseStarted.value) return
  waiting_for_answer.value = false
  // 播放正确的音效
  if (exercise_type.value == 'fill_word'){
    correctAudio.currentTime = 0
    correctAudio.play()
    return
  }
  setTimeout(()=>{
    correctAudio.currentTime = 0
    correctAudio.play()
  }, 1000)
}

// 出题
function emitExercise() {
  if (currentIndex.value < practice_data.value.words.length) {
    // 获取当前单词
    const currentWord = practice_data.value.words[currentIndex.value];
    // 设置题型
    if ( !currentWord.exercise_type){
      return
    } else {
      exercise_type.value = currentWord.exercise_type
    }
     
    //出题
    const imgIndex = Math.floor(Math.random() * currentWord.img_srcs.length); // 从img_srcs随机选择一张图片
    const imgSrc = currentWord.img_srcs[imgIndex]; // 构建图片路径
    // 构建wordData
    wordData.value = {
      id: currentWord.word_id,
      word: currentWord.word,
      phonic: currentWord.phonic,
      sentence: currentWord.sentence,
      img_src: imgSrc
    };

    waiting_for_answer.value = true;
  } else {
    exerciseStarted.value = false; // 所有单词已经回答完毕，结束
    ready.value = false
    wordData.value = {}
    // 送后端，生成下一次题目
    to_backend()
  }
}

// 处理phonic点击事件
const handlePhonicClick = (phonic) => {
  selectedPhonic.value = phonic  
}

// 处理底部评价按钮
function handleButtonClick(type) {
  const proficiency = {'next':0,'know':1,'master':2}
  practice_data.value.words[currentIndex.value].proficiency = proficiency[type]
  currentIndex.value++; // 移至下一题
  emitExercise(); // 出下一题
  waiting_for_answer.value = true;
  selectedPhonic.value = null;
}
// 从后端获取practice_data
async function get_practice_data() {
  await apiClient.get(ROUTES_WITH_BASE_URL.get_practice_data)
  .then((res)=>{
    if ('error' in res.data){console.log(res.data.error)}
    // 遍历每个phonics对象，处理audio_src数组
    res.data.practice_data.phonics.forEach(phonic => {
      phonic.audio_src = ROUTES_WITH_BASE_URL.levels + phonic.audio_src.replace(/\\/g, '/')
    });
    // 遍历每个word对象，处理img_srcs数组
    res.data.practice_data.words.forEach(word => {
      word.img_srcs = word.img_srcs.map(imgSrc => {
        // 将反斜杠替换为正斜杠并添加路径前缀
        const imgSrcPath = imgSrc.replace(/\\/g, '/');
        // 使用require创建引用
        return ROUTES_WITH_BASE_URL.levels+imgSrcPath
      });
    });

    practice_data.value = res.data.practice_data
    console.log(practice_data.value)
    ready.value = true
  })
  .catch((err)=>{console.log("error:",err)})
}

// 将data传回去
async function to_backend() {
  const data_after_practice = practice_data.value.words.map(word =>{
    return {
      // id:word.id,
      word:word.word,
      phonic:word.phonic,
      phonic_display:word.phonic_display,
      exercise_type:word.exercise_type,
      proficiency:word.proficiency
    }
  })

  await apiClient.post(ROUTES_WITH_BASE_URL.data_after_practice, data_after_practice)
}
onMounted(() => {})
</script>

<style scoped>
.control-buttons {
  display: flex;
  justify-content: flex-end; 
  gap: 10px; /* 间隔 */
}

.control-buttons button {
  padding: 10px 20px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.control-buttons button:hover {
  background-color: #0056b3;
}

</style>
