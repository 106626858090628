<template>
  <div class="overall" v-if="gained_words_data">
    <p>Gained Words</p>
    <ul>
        <li v-for="(item,index) in gained_words_data" :key="index">
            {{item.exercise_type}}: {{item.quantity}}
        </li>
    </ul>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { apiClient } from "@/api/apiClient";
import { ROUTES_WITH_BASE_URL } from "@/api/apiRouters";

const gained_words_data = ref(null)

// 从后端获取data示例: [{'exercise_type':'fill',quantity:43}]
async function get_gained_words_data() {
    debugger
  await apiClient.get(ROUTES_WITH_BASE_URL.get_gained_words_data)
  .then((res)=>{
    gained_words_data.value = res.data
  })
  .catch((err)=>{console.log("error:",err)})
}

onMounted(() => {
  get_gained_words_data()
})
</script>

<style scoped>
ul{
    margin-top:0;
}
li{
    padding: 0;
    margin: .1em auto;
}
p{
    margin-bottom: 0;
    padding-bottom: 0;
}

</style>
