<template>
  <div v-if="progressBarInfoReady">
    <!-- 使用子组件，并传递progress_bar_info作为props -->
    <ProgressBar :progress-bar-info="progressBarInfo" />
  </div>
  <gained-words />
  <div>
    <p>Medals</p>
    <img v-if="current_level_id >= 83" src="@/assets/CVC.jpg" width="180px" alt="">
  </div>
  <div>
    <input type="text" v-model.number="level">
    <button @click="set_level">Go to</button>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { apiClient } from "@/api/apiClient";
import { ROUTES_WITH_BASE_URL } from "@/api/apiRouters";
import ProgressBar from '@/components/ProgressBar.vue'; // 假设子组件命名为ProgressBar.vue
import GainedWords from '@/components/GainedWords.vue'; // 假设子组件命名为ProgressBar.vue

const level = ref(0)
function set_level() {
  apiClient.get(ROUTES_WITH_BASE_URL.set_level, {params:{ level_id: level.value }})
}

const progressBarInfoReady = ref(false)
// 父组件的数据
const progressBarInfo = ref({})
const current_level_id = ref(0)
// 定义获取进度的方法
const fetchProfile = async () => {
  try {
    const response = await apiClient.get(ROUTES_WITH_BASE_URL.profile);
    progressBarInfo.value = response.data.progress_in_levels
    current_level_id.value = response.data.current_level_id
    progressBarInfoReady.value = true
    console.log(current_level_id.value)
  } catch (error) {
    console.error('Failed to fetch progress:', error);
  }
};

// 组件挂载后请求数据
onMounted(fetchProfile);
</script>

<style scoped>
p{
  padding-bottom: 0;
  margin-bottom: 0;
}
</style>